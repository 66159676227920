* {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
}

ul,
li {
  list-style: none;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #4b49ac;
  outline: 1px solid #4b49ac;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.react-datepicker-popper {
  z-index: 100 !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
  z-index: 100 !important;
}
.special-cell {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.special-cell--negative {
  color: red;
}

.special-cell:hover {
  background-color: #c2c1bd;
  transition: 0.25s ease all;
  cursor: pointer;
  color: black;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4b49ac;
  outline: 1px solid #4b49ac;
}


.password-field {
  font-family: text-security-disc;
  /* Use -webkit-text-security if the browser supports it */
  -webkit-text-security: disc;
}

.react-tel-input .flag-dropdown {
  background: none;
  border: none;
}

.react-tel-input .selected-flag:hover {
  background: #393A3E;
  border: none;
}

.react-tel-input .selected-flag .open {
  background: #4B4B50;
}

.react-tel-input .country-list {
  background: #28292E;
}

.react-tel-input .country-list .country:hover {
  background: #4B4B50;
}
.react-tel-input .country-list .country.highlight {
  background: #393A3E;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #4B4B50;
}
